<template>
  <el-container class="home-container visual-base">
    <!-- <div class="visual-starry-bg"></div> -->
    <!-- <el-header v-show="this.$route.path != '/Show'"> -->
    <el-header>
      <div>
        <audio id="audio" loop="loop" :src="thisAudioUrl" />
      </div>
      <el-menu
        :router="true"
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#000"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item
          v-for="item in menuList"
          :index="item.index + ''"
          v-if="item.type == tempRoute"
        >
          <div>{{ item.label }}</div>
        </el-menu-item>

        <!-- time -->
        <div class="time">{{ time }}</div>
        <!-- <div class="iconfont icon-guanli guanli-icon" @click="goSelect"></div>
        <div class="iconfont icon-zhuye home-icon" @click="goShow"></div> -->

        <!-- el-icon-close-notification   关闭 -->
        <div
          class="el-icon-bell volume"
          v-if="startaudio != -1"
          @click="initPlayaudio"
        ></div>
        <div
          class="el-icon-close-notification volume1"
          v-if="startaudio == -1"
          @click="initPlayaudio"
        ></div>
        <div class="el-icon-menu goMenu" @click="goMenu"></div>
      </el-menu>
    </el-header>

    <el-main id="temp">
      <!-- 路由出口 -->
      <router-view
        ref="childFunction"
        @routerIsHistoryFunction="routerIsHistoryFunction"
        @routerIsIndexFunction="routerIsIndexFunction"
        @routerIsmapBuidingHomeTFFunction="routerIsmapBuidingHomeTFFunction"
      ></router-view>
    </el-main>
  </el-container>
</template>

<script>
import getTime from "../../utils/time.js";
import dataStorage from "@/../utils/dataStorage.js";

import history from "@/views/history/History";
import show from "@/views/Show";
import mapBuidingHome from "@/views/mapBuiding/MapBuidingHome";
import BaseData from "@/assets/config/BaseData.js";
import crtDeviceCoordinate from "../api/manageApi/CrtDeviceCoordinate";

export default {
  name: "Home",

  components: { mapBuidingHome, history, show },
  created() {
    this.thisAudioUrl = BaseData.baseUrl + "crtPicture/fault.mp3";
    window.toCRT = this.toCRT;
     window.showVideo = this.showVideo;

    if (this.$route.params.msg == "主页") {
      this.goShow();
    }

    var arr = this.$route.path.split("/");
    this.activeIndex = arr[1];
    if (this.$route.params.msg != null) {
      this.tempRoute = this.$route.params.msg;
    } else {
      for (var item of this.menuList) {
        if ("/" + item.index == this.$route.path) {
          this.tempRoute = item.type;
          break;
        }
      }
    }

    this.timeTable();
  },
  watch: {
    startaudio: function () {
      if (this.startaudio != -1) {
        if (this.startaudio == 1) {
          this.playaudio();
        } else {
          this.stopAudio();
        }
      }
    },
    $route(to, from) {
      // console.log(to.path);
      if (to.params.msg != null) {
        this.tempRoute = to.params.msg;
      } else {
        for (var item of this.menuList) {
          if ("/" + item.index == to.path) {
            this.tempRoute = item.type;
            break;
          }
        }
      }
    },
  },
  data() {
    return {
      time: "yyyy-MM-dd HH:mm:ss",
      thisAudioUrl: "",
      // 如果是客户端版本的 这需要为-1 交互才能播放
      // startaudio:-1,
      // 如果是网页版本的 这需要为0 不交互自动播放
      startaudio: 0,
      tempRoute: "",
      activeIndex: "",
      menuList: [
        {
          label: "设备管理",
          index: "DeviceInfoManagement",
          type: "我的设备",
        },
        {
          label: "设备类型管理",
          index: "DeviceTypeManagement",
          type: "我的设备",
        },
        { label: "CRT点位展示", index: "CRTShow", type: "CRT管理" },
        {
          label: "CRT图片管理",
          index: "CRTManagement",
          type: "CRT管理",
        },
        {
          label: "一键报警",
          index: "AKeyAlarm",
          type: "一键报警",
        },
        {
          label: "CRT点位管理",
          index: "CRTCoordinate",
          type: "CRT管理",
        },
        {
          label: "首页CRT点位管理",
          index: "indexCRTCoordinate",
          type: "CRT管理",
        },
        {
          label: "报警历史记录",
          index: "History",
          type: "历史记录",
        },
        {
          label: "历史记录",
          index: "HistoryValue",
          type: "历史记录",
        },
        { label: "查看信息", index: "MapBuiding", type: "数据可视化" },
        {
          label: "创建建筑物",
          index: "SelectLoLa",
          type: "数据可视化",
        },
        {
          label: "订阅管理",
          index: "SubscribeManagement",
          type: "订阅管理",
        },
        {
          label: " （ 权限系统管理",
          index: "PermissionSysManagement",
          type: "权限管理",
        },
        {
          label: "权限类型管理",
          index: "PermissionTypeManagement",
          type: "权限管理",
        },
        {
          label: "权限类型分配 ） ",
          index: "PermissionTypeAllot",
          type: "权限管理",
        },
        {
          label: " （ 通知系统管理",
          index: "NoticeSysManagement",
          type: "权限管理",
        },
        {
          label: "通知类型管理",
          index: "NoticeTypeManagement",
          type: "权限管理",
        },
        {
          label: "通知类型分配 ） ",
          index: "NoticeTypeAllot",
          type: "权限管理",
        },
        {
          label: " （ 公司管理系统",
          index: "Company",
          type: "权限管理",
        },
        {
          label: "账号权限分配",
          index: "PermissionAssignment",
          type: "权限管理",
        },
        {
          label: "账号管理 ） ",
          index: "AccountManagement",
          type: "权限管理",
        },
        {
          label: "操作日志",
          index: "OperationLog",
          type: "操作日志",
        },
      ],
      //看当前界面是否显示的是地图
      routerIsmapBuidingHome: false,
      show: true,
      timer: null,
      routeIndexHome: false,
      routerIsHistory: false,
      routerIsIndex: false,

      path:
        "ws://" +
        BaseData.serverIP +
        ":" +
        BaseData.serverPort +
        "/websocket/" +
        dataStorage.getBaseData("socketUserName"),
    };
  },
  beforeDestroy() {
    this.closeWebsocket();
  },

  methods: {
    timeTable() {
      let timeObj = getTime.getTime();
      this.time = timeObj.dayTime();
      let ils = 0;
      this.timer = setInterval(() => {
        ils++;
        this.time = timeObj.dayTime();
      }, 1000);
    },

    initPlayaudio() {
      if (this.startaudio == -1) {
        var audio = document.getElementById("audio");
        audio.play(); //播放一段音频
        audio.pause(); //暂停一段音频
        this.startaudio = 0;
      } else {
        this.startaudio = -1;
        var audio = document.getElementById("audio");
        audio.pause(); //暂停一段音频
      }
    },
    playaudio() {
      var audio = document.getElementById("audio");
      audio.play(); //播放一段音频
      //audio.pause()  //暂停一段音频
    },
    stopAudio() {
      var audio = document.getElementById("audio");
      audio.pause(); //暂停一段音频
    },
    startplay() {
      if (this.startaudio != -1) {
        this.startaudio = 1;
        if (this.startaudio == 1) {
        } else {
          this.startaudio = 1; //改变值
        }
      }
    },
    stopPlay() {
      if (this.startaudio != -1) {
        if (this.startaudio == 0) {
        } else {
          this.startaudio = 0; //改变值
        }
      }
    },
    goMenu() {
      // this.$router.replace('/Select.vue')
      this.$router.push("/Select");

      // console.log(this.springFrame());
    },
    closeWebsocket() {
      this.socket.close();
    },
    goShow() {
      this.$router.push({ path: "/Show" });
    },
    goSelect() {
      // window.history.back();
      this.$router.push({ path: "/Select" });
    },

    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
      // console.log(this.menuList);
    },

    init() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        this.socket = new WebSocket(this.path);
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;

        // this.$refs.childFunction.dataHandlerIndex();
      }
    },

    routerIsmapBuidingHomeTFFunction(tf) {
      this.routerIsmapBuidingHome = tf;
    },
    routerIsHistoryFunction(tf) {
      this.routerIsHistory = tf;
    },

    routerIsIndexFunction(tf) {
      this.routerIsIndex = tf;
    },
    open() {
      console.log("socket连接成功");
    },
    error() {
      console.log("连接失败");
      this.socket = new WebSocket(this.path);
      // 监听socket连接
      this.socket.onopen = this.open;
      // 监听socket错误信息
      this.socket.onerror = this.error;
      // 监听socket消息
      this.socket.onmessage = this.getMessage;
    },
    getMessage(msg) {
      console.log("收到的消息===============");
      console.log(msg);

      var jsonMsg = JSON.parse(msg.data);
      // 此方法为弹框
      this.springFrame(jsonMsg);
      // 此方法为地图界面的跳转报警数据push
      this.pushMapBuidingAlamDate(jsonMsg);
      //报警失联故障刷新历史记录页面
      this.refreshHistory(jsonMsg);
      //报警失联故障如果再首页   高亮显示crt
      this.blingblingIndexCRTIcon(jsonMsg);
    },
    send() {
      this.socket.send("params");
    },
    close() {
      console.log("socket已经关闭");
    },
    springFrame(msg) {
    
      let message =
        "【" +
        msg.typeName +
        "-" +
        msg.deviceId +
        "】发生了----------" +
        msg.value;
        console.log(msg);

        if (msg.videoUrl == "") {
        }else{
          if (msg.value.indexOf("警") != -1) {
            console.log(msg.videoUrl);
            this.showVideo(msg.videoUrl)
          }
        }
      this.getForFrame({
        deviceId: msg.deviceId,
        title: msg.type,
        message: message,
        videoUrl:msg.videoUrl,
        typeObject:
          msg.value.indexOf("警") != -1
            ? {
                className: "notify-warning",
                icon: "el-icon-warning",
                color: "#000",
              }
            : msg.value.indexOf("故障") != -1
            ? {
                className: "notify-fault",
                icon: "el-icon-info",
                color: "#000",
              }
            : {},
      });
    }, 

    showVideo(videoUrl) {
            if (videoUrl) {
                window.location.href = videoUrl;
            } else {
                this.$message({
                    type: "error",
                    message: "该设备未绑定任何摄像头！",
                });
            }
        },

    // 只有当为地图界面的时候才会执行 否则报错 回头 弄下那个通知 只有当前是地图界面才会 执行这个方法
    pushMapBuidingAlamDate(jsonStr) {
      if (this.routerIsmapBuidingHome) {
        this.$refs.childFunction.dataHandler(jsonStr);

        // this.$refs.childFunction.dataHandler(
        //   JSON.parse(
        //     '{"CRTId":"774877487748666","deviceId":"774877487748666","报警":"报警"}'
        //   )
        // );
      }
    },
    blingblingIndexCRTIcon(jsonMsg) {
      // console.log(jsonMsg);
      if (
        jsonMsg.value.indexOf("警") > -1 ||
        jsonMsg.value.indexOf("失联") > -1 ||
        jsonMsg.value.indexOf("故障") > -1
      ) {
        // console.log(this.routerIsIndex);
        if (this.routerIsIndex) {
          this.$refs.childFunction.dataHandlerIndex(jsonMsg);
        }
      }
    },

    //刷新历史记录页面
    refreshHistory(jsonMsg) {
      if (
        jsonMsg.value.indexOf("警") > -1 ||
        jsonMsg.value.indexOf("失联") > -1 ||
        jsonMsg.value.indexOf("故障") > -1
      ) {
        // console.log("===================================");
        // console.log(this.routerIsHistory);
        if (this.routerIsHistory) {
          this.$refs.childFunction.dataHandlerHistory();
        }
      }
    },
    getForFrame(msg) {
      var msgString = 
      "<h4 style='color:" +
          msg.typeObject.color +
          "'>" +
          msg.message +
          "</h4><button onclick='toCRT(\"" +
          msg.deviceId +
          "\")'>CRT</button>"+
          (msg.videoUrl ==""? "":
          "<button style='margin-left:10px' onclick='showVideo(\"" +
          msg.videoUrl +
          "\")'>查看视频</button>")
      // 报警信息弹框
      this.$notify({
        title: msg.title,
        dangerouslyUseHTMLString: true,
        customClass: msg.typeObject.className,
        message:msgString,
        duration: 0,
        position: "bottom-right",
        iconClass: msg.typeObject.icon,
        onClick: this.jump,
        onClose: this.closeNotify,
      });

      console.log(this.$notify.length);

      this.startplay();
    },
    toCRT(deviceId) {
      crtDeviceCoordinate.getCoordinateByDeviceId(deviceId).then((res) => {
        if (res.data.success) {
          this.$router.push({
            name: "CRTShow",
            params: {
              CRTId: res.data.data.data.crtPictureId,
              deviceId: res.data.data.data.deviceInfoId,
            },
          });
        } else {
          this.$message({
            type: "warning",
            message: "该设备尚未添加点位！",
          });
        }
      });
      this.stopPlay();
    },
    jump() {
      //跳转到状态历史记录页面
      this.$router.push({
        path: "History",
      });
      this.stopPlay();
    },

    closeNotify() {
      this.stopPlay();
    },
  },
  mounted() {
    // 初始化socket
    this.init();
  },

  destroyed() {
    clearInterval(this.timer);
    this.socket.onclose = this.close;
  },
};
</script>

<style lang="scss" scoped>
#temp {
  padding: 0px;
  overflow: hidden;
}
.home-container {
  height: 100vh;
}
.el-header {
  height: auto !important;
  padding: 0;
}
.el-menu {
  display: flex;
}

.el-main {
  flex-basis: 0px;
}
.notify-warning {
  background: rgba(177, 58, 60, 0.979) !important;
  box-shadow: 3px 3px 2px rgb(235, 106, 106) !important;
}
.el-icon-warning {
  color: #fe2e2e !important;
}
.notify-fault {
  background: rgba(238, 217, 33, 0.5) !important;
  box-shadow: 3px 3px 2px rgb(208, 238, 138) !important;
}
.el-icon-info {
  color: #f3f781 !important;
}
.v-modal {
  display: none;
}
.goMenu {
  font-size: 22px;
  display: inline-block;
  line-height: 60px;
  position: absolute;
  right: 20px;
}
.goMenu:hover {
  color: #f3f781;
}
.volume {
  font-size: 22px;
  display: inline-block;
  line-height: 60px;
  position: absolute;
  right: 60px;
}
.volume1 {
  font-size: 22px;
  display: inline-block;
  line-height: 60px;
  position: absolute;
  right: 60px;
}
.time {
  font-size: 16px;
  position: absolute;
  right: 120px;
  line-height: 60px;
  cursor: pointer;
}

.time:hover {
  color: palegoldenrod;
}
</style>
